import { Injectable } from "@angular/core";
import { UtilsService } from "./utils.service";

@Injectable({
    providedIn: "root",
})
export class PermissionsService {

    constructor(
        private utils: UtilsService
    ) { }

    get permissionsAlerts() {
        return this.buildPermissions("Alertas", "alerts",
            [{ name: "painel", description: "Painel De Controle" }]
        );
    }

    get permissionsFloor() {
        return this.buildPermissions("Andares", "floor");
    }

    get permissionsJobTitle() {
        return this.buildPermissions("Cargos", "jobTitle",
            [{ name: "audit", description: "Auditoria" }]
        );
    }

    get permissionsClassifications() {
        return this.buildPermissions("Classificações", "classifications");
    }

    get permissionsColaborators() {
        return this.buildPermissions("Colaboradores", "colaborators");
    }

    get permissionsConfigurations() {
        return this.buildPermissions("Configurações", "configurations",
            [{ name: "addEdit", description: "Adicionar/Editar" }]
        );
    }

    get permissionsPrintConfigurations() {
        return this.buildPermissions("Configurações de impressão", "printConfigurations",
            [{ name: "addEdit", description: "Adicionar/Editar" }]
        );
    }

    get permissionsContract() {
        return this.buildPermissions("Contratos", "contracts");
    }

    get permissionsPointControl() {
        return this.buildPermissions("Controle de Ponto", "pointControl",
            [{ name: "close", description: "Fechar" }]
        );
    }

    get permissionsEntityInfo() {
        return [
            { permission: 'entityInfo-restrict', description: 'Restrito' },
            { permission: 'entityInfo-all', description: 'Todos' },
        ]
    }

    get permissionsBudgetData() {
        return this.buildPermissions("Dados Orçamentários", "budgetData");
    }

    get permissionsServiceDescriptions() {
        return this.buildPermissions("Descrições-Serviço", "serviceDescriptions");
    }

    get permissionsServiceDiary() {
        return this.buildPermissions("Diario de serviço", "serviceDiary");
    }

    get permissionsDevices() {
        return this.buildPermissions("Dispositivos", "devices");
    }

    get permissionsEntities() {
        return this.buildPermissions("Entidades", "entities");
    }

    get permissionsEquipments() {
        return this.buildPermissions("Equipamentos", "equipments");
    }

    get permissionsShift() {
        return this.buildPermissions("Escalas de Trabalho", "shift");
    }

    get permissionsDueVacation() {
        return this.buildPermissions("Férias/Afastamento", "dueVacation");
    }

    get permissionsTeam() {
        return this.buildPermissions("Equipes", "team");
    }

    get permissionsForm() {
        return this.buildPermissions("Formulários", "form",
            [{ name: "schedule", description: "Agendar" }]
        );
    }

    get permissionsServiceLocation() {
        return this.buildPermissions("Formulários", "form",
            [{ name: "servieLocation", description: "Localizações" }]
        );
    }

    get permissionsServiceMaterial() {
        return this.buildPermissions("Material-Serviço", "serviceMaterial",
            [{ name: "servieLocation", description: "Localizações" }]
        );
    }

    get permissionsDashboard() {
        return [
            { permission: 'dashboard-read', description: 'Dashboard - Ler' },
            { permission: 'dashboard-view', description: 'Dashboard - Ver relatório gerencial' },
        ]
    }

    get permissionsLocation() {
        const controller = "Localizações";
        return [
            { permission: 'location-events', description: `${controller} - Eventos` },
            { permission: 'location-logs', description: `${controller} - Logs` },
            { permission: 'location-os-geo', description: `${controller} - Ordens Geolocalizadas` },
            { permission: 'location-to', description: `${controller} - Tocantins` },
        ]
    }

    get permissionsLocationHistory() {
        return [
            { permission: 'location-history-print', description: `Historico de Localização - Imprimir` },
            { permission: 'location-history-read', description: `Historico de Localização - Ler` },
            { permission: 'location-history-time', description: `Historico de Localização - Tempo` },
        ]
    }

    get permissionsMeasurements() {
        return this.buildPermissions("Medições anteriores", "measurements",
            [{ name: "read-details", description: "Ler detalhes" }]
        );
    }

    get permissionsMicrocontroller() {
        return this.buildPermissions("Microcontroladores", "microcontroller",
            [
                { name: "turnoff", description: "Desligar" },
                { name: "turnon", description: "Ligar" },
                { name: "read-events", description: "Ler eventos" },
                { name: "read-logs", description: "Ler logs" }
            ]
        );
    }

    get permissionsConcierge() {
        return this.buildPermissions("Portarias", "concierge");
    }

    get permissionsContractType() {
        return this.buildPermissions("Modalidade de Contratação", "contractType");
    }

    get permissionsControlParams() {
        return this.buildPermissions("Parâmetros de Controle", "controlParams");
    }

    get permissionsPermissions() {
        return this.buildPermissions("Permissões", "permissions");
    }

    get permissionsRegions() {
        return this.buildPermissions("Regiões", "regions");
    }

    get permissionsSectors() {
        return this.buildPermissions("Setores", "sectors");
    }

    get permissionsSystems() {
        return this.buildPermissions("Sistemas", "systems");
    }

    get permissionsSubSystems() {
        return this.buildPermissions("SubSistemas", "subSystems");
    }

    get permissionsEquipmentTypes() {
        return this.buildPermissions("Tipos de equipamentos", "equipmentTypes");
    }

    get permissionsFormTypes() {
        return this.buildPermissions("Tipos de formulários", "formTypes");
    }

    get permissionsAccount() {
        return this.buildPermissions("Usuários", "account",
            [
                { name: "details", description: "Detalhes" },
                { name: "add-permissions", description: "Atribuir permissões" }
            ]
        );
    }

    get permissionsRequests() {
        return this.buildPermissions("Requisições", "requests",
            [
                { name: "approve", description: "Aprovar" },
                { name: "cancel", description: "Cancelar" }
            ]
        );
    }

    get permissionsSolicitations() {
        return this.buildPermissions("Solicitações", "solicitations",
            [
                { name: "approve-disapprove", description: "Aprovar/Reprovar" }
            ]
        );
    }

    get permissionsMessages() {
        return [
            { permission: 'messages', description: `Mensagens - Template` },
        ]
    }

    get permissionsOrders() {
        return this.buildPermissions("Ordens", "orders",
            this.osOthersPermissions
        );
    }

    get permissionsAlertLogs() {
        return this.buildPermissions("Alertas", "alert");
    }

    buildPermissions(
        permission: string,
        controller: string,
        othersPermissions?: { name: string, description: string }[]
    ) {
        const permissionsList = [
            { permission: `${controller}-add`, description: `${permission} - Adicionar` },
            { permission: `${controller}-edit`, description: `${permission} - Editar` },
            { permission: `${controller}-delete`, description: `${permission} - Excluir` },
            { permission: `${controller}-read`, description: `${permission} - Ler` },
        ]

        othersPermissions?.forEach(element => {
            permissionsList.push({
                permission: `${controller}-${element.name}`,
                description: `${permission} - ${element.description}`
            })
        });

        return permissionsList;
    }

    get permissionsList() {
        return [
            ...this.permissionsAlerts,
            ...this.permissionsFloor,
            ...this.permissionsJobTitle,
            ...this.permissionsClassifications,
            ...this.permissionsColaborators,
            ...this.permissionsConfigurations,
            ...this.permissionsPrintConfigurations,
            ...this.permissionsContract,
            ...this.permissionsPointControl,
            ...this.permissionsEntityInfo,
            ...this.permissionsBudgetData,
            ...this.permissionsServiceDescriptions,
            ...this.permissionsServiceDiary,
            ...this.permissionsDevices,
            ...this.permissionsEntities,
            ...this.permissionsEquipments,
            ...this.permissionsShift,
            ...this.permissionsDueVacation,
            ...this.permissionsTeam,
            ...this.permissionsForm,
            ...this.permissionsServiceLocation,
            ...this.permissionsServiceMaterial,
            ...this.permissionsDashboard,
            ...this.permissionsLocation,
            ...this.permissionsLocationHistory,
            ...this.permissionsMeasurements,
            ...this.permissionsMicrocontroller,
            ...this.permissionsContractType,
            ...this.permissionsControlParams,
            ...this.permissionsPermissions,
            ...this.permissionsRegions,
            ...this.permissionsSectors,
            ...this.permissionsSystems,
            ...this.permissionsSubSystems,
            ...this.permissionsEquipmentTypes,
            ...this.permissionsFormTypes,
            ...this.permissionsRequests,
            ...this.permissionsSolicitations,
            ...this.permissionsMessages,
            ...this.permissionsOrders,
            ...this.permissionsAccount,
            ...this.permissionsAlertLogs,
            ...this.permissionsConcierge
        ]
    }

    getBasePermissions(controller: string) {
        return {
            add: `${controller}-add`,
            edit: `${controller}-edit`,
            read: `${controller}-read`,
            delete: `${controller}-delete`,
        }
    }

    get osOthersPermissions() {
        return [
            { name: "paused", description: "Pausada" },
            { name: "release", description: "Liberar" },
            { name: "not-released", description: "Não liberar" },
            { name: "not-confirm", description: "Não confirmar" },
            { name: "read-details", description: "Ler detalhes" },
            { name: "change-status", description: "Alterar status" },
            { name: "print-details", description: "Imprimir detalhado" },
            { name: "print-individual", description: "Imprimir individual" },
            { name: "print-inverted", description: "Imprimir todas (invertido)" },
            { name: "view-saved-prints", description: "Visualizar impressões salvas" },
            {
                name: "print-summary-classification",
                description: "Imprimir todas (resumido com classificação)"
            },
            {
                name: "print-all-summary",
                description: "Imprimir todas (resumido)"
            },
            {
                name: "print-all-details",
                description: "Imprimir todas (detalhado)"
            },
            {
                name: "print-summary-inverted",
                description: "Imprimir todas (invertido resumido)"
            },
            {
                name: "print-all-equipments",
                description: "Imprimir todas (resumido com equipamento)"
            },
            { name: "approve", description: "Aprovar" },
            { name: "audit", description: "Auditoria" },
            { name: "cancel", description: "Cancelar" },
            { name: "confirm", description: "Confirmar" },
            { name: "execution", description: "Executar" },
            { name: "inprogress", description: "Em andamento" },
            { name: "classification", description: "Classificar" },
            { name: "add-corrective", description: "Adicionar corretiva" },
            { name: "add-preventive", description: "Adicionar preventiva" },
            { name: "sent-for-approval", description: "Enviada para aprovação" },
        ]
    }

    buildObjPermissions(controller: string, arrPermissions: { name: string, description: string }[]): any {
        let permissions = this.getBasePermissions(controller);
        arrPermissions?.forEach(
            permission => {
                Object.assign(
                    permissions,
                    {
                        [permission.name]: `${controller}-${permission.name}`,
                    }
                )
            });
        return permissions
    }

    get noPermissionText() {
        return "Usuário sem permissão para acessar este item."
    }
}